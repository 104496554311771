function setStyle({ id, name } = {}, style = {}) {
  // If no selector is provided, exit the function
  if (!id && !name) return;

  // Select the element based on id or name
  const element = id
    ? document.getElementById(id)
    : document.getElementsByName(name)[0];

  // If no element is found, exit the function
  if (!element) return;

  // Apply each style property to the element
  Object.entries(style).forEach(([property, value]) => {
    element.style[property] = value;
  });
}

function renderElement(root, type, props) {
  const element = document.createElement(type);
  Object.entries(props).forEach(([property, value]) => {
    element[property] = value;
  });
  root.appendChild(element);
  return element;
}

(async function () {
  // Comment out the line below when developing locally
  // const baseUrl = "http://localhost:3000";
  const baseUrl = "https://25.tools";

  const getExcludedPages = () => {
    const script = document.getElementById("feedback-widget-script");
    const excludedpages = script.getAttribute("data-excluded-pages");

    if (!excludedpages) {
      return [];
    }

    return excludedpages.split(",");
  };
  const getIncludedPages = () => {
    const script = document.getElementById("feedback-widget-script");
    const includedPages = script.getAttribute("data-included-pages");

    if (!includedPages) {
      return [];
    }

    return includedPages.split(",");
  };

  const hasIncludedPages = () => {
    const includedPages = getIncludedPages();
    return includedPages.length > 0;
  };

  if (hasIncludedPages()) {
    const isIncludedPage = () => {
      const includedPages = getIncludedPages();
      const pathname = window.location.pathname;

      return includedPages.some((page) => {
        // Replace wildcard '*' with a regex equivalent '.*'
        const regexPattern = page.replace(/\*/g, ".*");
        const regex = new RegExp(`^${regexPattern}$`);
        return regex.test(pathname);
      });
    };

    if (!isIncludedPage()) {
      return;
    }
  }

  const isExcludedPage = () => {
    const excludedPages = getExcludedPages();
    const pathname = window.location.pathname;

    return [...excludedPages, "/feedback-widget/*", "/waitlist-widget/*"].some(
      (page) => {
        // Replace wildcard '*' with a regex equivalent '.*'
        const regexPattern = page.replace(/\*/g, ".*");
        const regex = new RegExp(`^${regexPattern}$`);
        return regex.test(pathname);
      }
    );
  };

  if (isExcludedPage()) {
    return;
  }

  // TODO: Figure out how to make this work in production.
  // We are currently building rollup in development mode and pushing it to production.
  // We should be building rollup in production mode before building the app.
  // This will allow us to use process.env.NODE_ENV in the widget.
  //
  // const isProduction = process.env.NODE_ENV === "production";
  // const baseUrl = isProduction ? "https://25.tools" : "http://localhost:3000";

  async function fetchConfig() {
    const script = document.getElementById("feedback-widget-script");
    const token = script.getAttribute("data-token");
    const response = await fetch(
      `${baseUrl}/api/feedback-widget/config?token=${token}`
    );
    const data = await response.json();

    return data.data;
  }

  function renderWidget(widget) {
    const {
      config: { position },
    } = widget;

    const openWidget = () => {
      setStyle({ id: "feedback-widget" }, { width: "300px" });
      setStyle({ id: "feedback-widget" }, { height: "420px" });
      setStyle({ name: "feedback-widget-button" }, { visibility: "hidden" });
      setStyle({ name: "feedback-widget-dialog" }, { visibility: "visible" });
    };

    const closeWidget = () => {
      setStyle(
        { id: "feedback-widget" },
        { width: position === "left" || position === "right" ? "35px" : "80px" }
      );
      setStyle(
        { id: "feedback-widget" },
        {
          height: position === "left" || position === "right" ? "80px" : "35px",
        }
      );

      setStyle({ name: "feedback-widget-button" }, { visibility: "visible" });
      setStyle({ name: "feedback-widget-dialog" }, { visibility: "hidden" });
    };

    const formatInitialStyle = (position) => {
      const style = {
        opacity: 1,
        visibility: "visible",
        "z-index": "99999999999999999999",
        position: "fixed",
        bottom: "0px",
        width: "84px",
        height: "84px",
        "max-width": "100%",
        "max-height": "calc(100% + 0px)",
        "min-height": "0px",
        "min-width": "0px",
        "background-color": "transparent",
        border: "0px",
        overflow: "hidden",
        right: "0px",
        transition: "none 0s ease 0s !important",
      };

      if (position === "bottom_right") {
        style.bottom = "0px";
        style.right = "0px";
      } else if (position === "bottom_left") {
        style.bottom = "0px";
        style.left = "0px";
      } else if (position === "top_right") {
        style.top = "0px";
        style.right = "0px";
      } else if (position === "top_left") {
        style.top = "0px";
        style.left = "0px";
      } else if (position === "bottom") {
        style.bottom = "0px";
        style.right = "50%";
        style.transform = "translateX(50%)";
      } else if (position === "top") {
        style.top = "0px";
        style.right = "50%";
        style.transform = "translateX(50%)";
      } else if (position === "left") {
        style.top = "50%";
        style.left = "0px";
        style.transform = "translateY(-50%)";
      } else if (position === "right") {
        style.top = "50%";
        style.right = "0px";
        style.transform = "translateY(-50%)";
      }

      const styleString = Object.entries(style)
        .map(([property, value]) => `${property}: ${value};`)
        .join(" ");

      return styleString;
    };

    const rootElement = renderElement(document.body, "div", {
      id: "feedback-widget",
      style: formatInitialStyle(position),
      // "opacity: 1; visibility: hidden; z-index: 2147483639; position: fixed; bottom: 0px; width: 100px; height: 100px; max-width: 100%; max-height: calc(100% + 0px); min-height: 0px; min-width: 0px; background-color: transparent; border: 0px; overflow: hidden; right: 0px; transition: none 0s ease 0s !important;",
    });

    renderElement(rootElement, "iframe", {
      title: "Feedback Widget",
      name: "feedback-widget-dialog",
      allow:
        "autoplay; display-capture *; microphone *; camera *; display-capture *; picture-in-picture *; fullscreen *; clipboard-write *;",
      src: `${baseUrl}/feedback-widget/widget?widget_id=${widget.id}`,
      allowtransparency: "true",
      style:
        "width: 100%; height: 100%; min-height: 0px; min-width: 0px; margin: 0px; padding: 0px; background-image: none; background-position: 0% 0%; background-size: initial; background-attachment: scroll; background-origin: initial; background-clip: initial; background-color: rgba(0, 0, 0, 0); border-width: 0px; float: none; color-scheme: normal; position: absolute; inset: 0px; transition: none 0s ease 0s !important; display: block; visibility: hidden;",
    });

    renderElement(rootElement, "iframe", {
      title: "Feedback Button",
      name: "feedback-widget-button",
      allow:
        "autoplay; display-capture *; microphone *; camera *; display-capture *; picture-in-picture *; fullscreen *; clipboard-write *;",
      src: `${baseUrl}/feedback-widget/button?widget_id=${widget.id}`,
      allowtransparency: "true",
      style:
        "width: 100%; height: 100%; min-height: 0px; min-width: 0px; margin: 0px; padding: 0px; background-image: none; background-position: 0% 0%; background-size: initial; background-attachment: scroll; background-origin: initial; background-clip: initial; background-color: rgba(0, 0, 0, 0); border-width: 0px; float: none; color-scheme: normal; position: absolute; inset: 0px; transition: none 0s ease 0s !important; display: block; visibility: visible;",
    });

    window.addEventListener("message", (event) => {
      // IMPORTANT: check the origin of the data!
      if (event.isTrusted && event.data.source === "feedback-widget") {
        if (event.data.payload.isOpen) {
          openWidget();
        } else {
          closeWidget();
        }
      } else {
        // The data was NOT sent from your site!
        // Be careful! Do not use it. This else branch is
        // here just for clarity, you usually shouldn't need it.
        return;
      }
    });
  }

  const widget = await fetchConfig();

  if (!widget) {
    console.error("Could not fetch widget");
    return;
  }

  // Render
  renderWidget(widget);
})();
